<template>
    <div class="managments-company-main">
        <div class="managments-company-main-title">
            <h2>מערכת נהלים</h2>
        </div>
        <div class="managments-company-main-wrapper">
                <component 
                    :is="state" 
                    @selected_dep="handle_selected_dep_to_preview"
                    :dep="selected_dep"
                    @back_from_managments_list="selected_dep.value=null; state='DepartmentsList'"
                />
           
        </div>
    </div>
</template>

<script>
import { ref, defineAsyncComponent } from 'vue'
import {get_departments_from_db,departments} from './admin/store'
export default {
    components:{
        DepartmentsList: defineAsyncComponent(()=>import('./client/DepartmentsList.vue')),
        ManagmentsList: defineAsyncComponent(()=>import('./client/ManagmentsList.vue')),
    },
    setup () {
        
        const search = ref('')

        const selected_dep = ref(null)
        
        const state = ref('DepartmentsList')

        const is_panding = ref(false)

        const handle_selected_dep_to_preview = (dep) => {
            selected_dep.value = dep
            console.log(selected_dep.value);
            state.value = 'ManagmentsList'
        }

        const init = async() => {
            try{
                is_panding.value = true
                await get_departments_from_db()

                console.log(departments.value);
            }catch(err){

            }finally{
                is_panding.value = false
            }
        }

        init()
        return {
            search,
            state,
            selected_dep,
            handle_selected_dep_to_preview,
            selected_dep
        }
    }
}
</script>

<style scoped>
    .managments-company-main{
        width: 100%;
        height: 100%;
        color: #fff;
        display: flex;
        flex-direction: column;
        padding: 0 5px;
    }
    .managments-company-main-title{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: var(--secondary);
    }
    .managments-company-main-wrapper{
        width: 100%;
        max-width: 1200px;
        height: calc(100% - 50px);
        margin: 0 auto;
    }
</style>